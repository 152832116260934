import React from 'react'
import { useAuth0 } from '../react-auth0-spa'
import Layout from '../components/Layout'
import Subscriptions from '../components/Subscriptions/Subscriptions'
import RegistrationRibbon from '../components/RegistrationRibbon'
import SocialSideBar from '../components/SocialSideBar'
import styles from './products.module.scss'
import DevicesIcon from '../images/png/start_devices.png'
import MicrophoneIcon from '../images/png/start_microphone.png'
import InteractiveMobileImage from '../images/png/products-mobile_interactive.png'
import InteractiveDesktopImage from '../images/png/products-desktop_interactive.png'
import ChartIcon from '../images/png/products-desktop_chart.png'
import LightbulbHatIcon from '../images/png/products-desktop_lightbulbhat.png'
import StarIcon from '../images/png/products-desktop_star.png'
import WorldIcon from '../images/png/products-desktop_world.png'
import LightbulbHeadIcon from '../images/png/products-desktop_lightbulbhead.png'
import MobileSMSIcon from '../images/png/products-mobile_mobileSMS.png'
import GooglePlayIcon from '../images/png/google_play.png'
import AppleIcon from '../images/png/apple.png'
import AcademyIcon from '../images/png/products-desktop_academy.png'
import PortraitIcon from '../images/png/products-desktop_portrait.png'
import DesktopIcon from '../images/png/desktop.png'

const ProductsMobile = () => {
  const { isAuthenticated } = useAuth0()
  const fluentWorldsLink_Google =
    'https://play.google.com/store/apps/details?id=com.threed_view.FluentWorlds'
  const fluentWorldsLink_Apple =
    'https://apps.apple.com/us/app/learn-english-fluentworlds-esl/id1047012845'
  const perfectAccentLink_Google =
    'https://play.google.com/store/apps/details?id=com.fluentworlds.accent'
  const perfectAccentLink_Apple =
    'https://apps.apple.com/us/app/perfectaccent/id1424636159'
  const academyLink_Desktop = 'https://www.fluentworlds.com/academy/'

  return (
    <Layout>
      <section className={styles.sectionOne}>
        <SocialSideBar />
        <div className={styles.imageContainer}>
          <div className={styles.textContainer}>
            <h1>¡Aprende inglés en cualquier lugar y en cualquier momento!</h1>
            <h4>
              Ten acceso a las 3 apps del sistema integrado de aprendizaje, y
              aprende a hablar inglés de forma rápida y fluida.
            </h4>
          </div>
        </div>
      </section>
      <section className={styles.sectionTwo}>
        <h1>Accede desde tu dispositivo móvil</h1>
        <h4>
          Elige tu opción ideal de <span>suscripción a continuación</span>
        </h4>
        <div className={styles.subscriptionsContainer}>
          <div className={styles.subscription}>
            <div className={styles.row}>
              <div className={styles.colOne}>
                <img
                  src={DevicesIcon}
                  alt="devices"
                  className={styles.sectionTwoIconMediumOne}
                />
              </div>
              <div className={styles.colTwo}>
                <h3>Fluent Worlds</h3>
                <p>
                  Aprende inglés en un sistema inmersivo en 3D, explorando
                  escenarios cotidianos.
                </p>
              </div>
            </div>
            <span className="line-break-3"></span>
            <div className={styles.bottomContainer}>
              <h5>Descárgala en</h5>
              <div className={styles.storeIconContainer}>
                <a
                  href={fluentWorldsLink_Google}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={GooglePlayIcon}
                    alt="google play"
                    className={styles.smallIcon}
                  />
                  <div className={styles.storeText}>Google Play</div>
                </a>
              </div>
              <div className={styles.storeIconContainer}>
                <a
                  href={fluentWorldsLink_Apple}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={AppleIcon}
                    alt="apple store"
                    className={styles.smallIcon}
                  />
                  <span className={styles.storeText}>App Store</span>
                </a>
              </div>
            </div>
          </div>
          <div className={styles.subscription}>
            <div className={styles.row}>
              <div className={styles.colOne}>
                <img
                  src={MicrophoneIcon}
                  alt="microphone"
                  className={styles.sectionTwoIconMediumTwo}
                />
              </div>
              <div className={styles.colTwo}>
                <h3>Perfect Accent</h3>
                <p>
                  Perfecciona tu pronunciación y amplía tu vocabulario
                  especializado.
                </p>
              </div>
            </div>
            <span className="line-break-3"></span>
            <div className={styles.bottomContainer}>
              <h5>Descárgala en</h5>
              <div className={styles.storeIconContainer}>
                <a
                  href={perfectAccentLink_Google}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={GooglePlayIcon}
                    alt="google play"
                    className={styles.smallIcon}
                  />
                  <span className={styles.storeText}>Google Play</span>
                </a>
              </div>
              <div className={styles.storeIconContainer}>
                <a
                  href={perfectAccentLink_Apple}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={AppleIcon}
                    alt="apple store"
                    className={styles.smallIcon}
                  />
                  <span className={styles.storeText}>App Store</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.sectionThree}>
        <div className={styles.left}>
          <img
            src={InteractiveMobileImage}
            alt="interactive mobile"
            className={styles.sectionThreeInteractive}
          />
        </div>
        <div className={styles.right}>
          <h1>Beneficios de</h1>
          <h1>Fluent Worlds Móvil</h1>
          <h4>
            Un sistema diferente{' '}
            <span>para aprender inglés en 3D desde cualquier lugar.</span>
          </h4>
          <div className={styles.numberedContainer}>
            <div className={styles.item}>
              <div className={styles.colOne}>
                =
                <img
                  src={ChartIcon}
                  alt="chart"
                  className={styles.sectionThreeIcon}
                />
              </div>
              <div className={styles.colTwo}>
                <h3>01.</h3>
                <p>Diferente</p>
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.colOne}>
                <img
                  src={WorldIcon}
                  alt="world"
                  className={styles.sectionThreeIcon}
                />
              </div>
              <div className={styles.colTwo}>
                <h3>04.</h3>
                <p>Inmersivo</p>
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.colOne}>
                <img
                  src={LightbulbHatIcon}
                  alt="light bulb 1"
                  className={styles.sectionThreeIcon}
                />
              </div>
              <div className={styles.colTwo}>
                <h3>02.</h3>
                <p>Intuitivo</p>
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.colOne}>
                <img
                  src={LightbulbHeadIcon}
                  alt="light bulb 2"
                  className={styles.sectionThreeIcon}
                />
              </div>
              <div className={styles.colTwo}>
                <h3>05.</h3>
                <p>Vivencial</p>
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.colOne}>
                <img
                  src={StarIcon}
                  alt="star"
                  className={styles.sectionThreeIcon}
                />
              </div>
              <div className={styles.colTwo}>
                <h3>03.</h3>
                <p>Entretenido</p>
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.colOne}>
                <img
                  src={MobileSMSIcon}
                  alt="mobile sms"
                  className={styles.sectionThreeIcon}
                />
              </div>
              <div className={styles.colTwo}>
                <h3>06.</h3>
                <p>Móvil</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.sectionFour}>
        <h1>Accede al sistema completo desde tu equipo</h1>
        <h4>
          Elige tu opción ideal de <span>suscripción a continuación</span>
        </h4>

        <div className={styles.subscriptionsContainer}>
          <div className={styles.subscription}>
            <img
              src={DevicesIcon}
              alt="devices"
              className={styles.sectionFourIconOne}
            />
            <h6>FluentWorlds</h6>
            <p>
              Aprende inglés en un sistema inmersivo en 3D, explorando
              escenarios cotidianos.
            </p>
            <span className="line-break-4"></span>
            <h5>Descárgala en</h5>
            <div className={styles.smallIconContainer}>
              <div className={styles.row}>
                <a
                  href={fluentWorldsLink_Google}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {' '}
                  <img
                    src={GooglePlayIcon}
                    alt="google play"
                    className={styles.smallIcon}
                  />
                  <span className={styles.storeText}>Google Play</span>
                </a>
              </div>
              <div className={styles.row}>
                <a
                  href={fluentWorldsLink_Apple}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={AppleIcon}
                    alt="apple store"
                    className={styles.smallIcon}
                  />
                  <span className={styles.storeText}>App Store</span>
                </a>
              </div>
            </div>
          </div>

          <div className={styles.subscription}>
            <img
              src={MicrophoneIcon}
              alt="microphone"
              className={styles.sectionFourIconTwo}
            />
            <h6>Perfect Accent</h6>
            <p>
              Perfecciona tu pronunciación y amplía tu vocabulario
              especializado.
            </p>
            <span className="line-break-4"></span>
            <h5>Descárgala en</h5>
            <div className={styles.smallIconContainer}>
              <div className={styles.row}>
                <a
                  href={perfectAccentLink_Google}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={GooglePlayIcon}
                    alt="google play"
                    className={styles.smallIcon}
                  />
                  <span className={styles.storeText}>Google Play</span>
                </a>
              </div>
              <div className={styles.row}>
                <a
                  href={perfectAccentLink_Apple}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={AppleIcon}
                    alt="apple store"
                    className={styles.smallIcon}
                  />
                  <span className={styles.storeText}>App Store</span>
                </a>
              </div>
            </div>
          </div>

          <div className={styles.subscription}>
            <img
              src={AcademyIcon}
              alt="academy"
              className={styles.sectionFourIconTwo}
            />
            <h6>Academy</h6>
            <p>
              Cursos en línea con profesores nativos, en grupos de hasta 15
              estudiantes de todo el mundo.
            </p>
            <span className="line-break-4"></span>
            <h5>Descárgala en</h5>
            <div className={styles.smallIconContainer}>
              <div className={styles.row}>
                <a
                  href={academyLink_Desktop}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={DesktopIcon}
                    alt="desktop"
                    className={styles.smallIcon}
                  />
                  <span className={styles.storeText}>Desktop</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.sectionFive}>
        <div className={styles.left}>
          <img
            src={InteractiveDesktopImage}
            alt="Interactive"
            className={styles.sectionFiveInteractive}
          />
        </div>
        <div className={styles.right}>
          <h1>Beneficios de</h1>
          <h1>Fluent Worlds Desktop</h1>
          <h4>
            Un sistema diferente{' '}
            <span>para aprender inglés en 3D desde cualquier lugar.</span>
          </h4>
          <div className={styles.numberedContainer}>
            <div className={styles.item}>
              <div className={styles.colOne}>
                <img
                  src={ChartIcon}
                  alt="chart"
                  className={styles.sectionFiveIcon}
                />
              </div>
              <div className={styles.colTwo}>
                <h3>01.</h3>
                <p>Diferente</p>
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.colOne}>
                <img
                  src={WorldIcon}
                  alt="world"
                  className={styles.sectionFiveIcon}
                />
              </div>
              <div className={styles.colTwo}>
                <h3>04.</h3>
                <p>Inmersivo</p>
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.colOne}>
                <img
                  src={LightbulbHatIcon}
                  alt="light bulb 1"
                  className={styles.sectionFiveIcon}
                />
              </div>
              <div className={styles.colTwo}>
                <h3>02.</h3>
                <p>Intuitivo</p>
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.colOne}>
                <img
                  src={LightbulbHeadIcon}
                  alt="light bulb 2"
                  className={styles.sectionFiveIcon}
                />
              </div>
              <div className={styles.colTwo}>
                <h3>05.</h3>
                <p>Vivencial</p>
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.colOne}>
                <img
                  src={StarIcon}
                  alt="star"
                  className={styles.sectionFiveIcon}
                />
              </div>
              <div className={styles.colTwo}>
                <h3>03.</h3>
                <p>Entretenido</p>
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.colOne}>
                <img
                  src={PortraitIcon}
                  alt="portrait"
                  className={styles.sectionFiveIcon}
                />
              </div>
              <div className={styles.colTwo}>
                <h3>06.</h3>
                <p>Personalizado</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.sectionSix}>
        <h1>Nuestros planes</h1>
        <h4>
          Elige tu opción ideal de <span>suscripción a continuación</span>
        </h4>
        <Subscriptions />
      </section>
      {!isAuthenticated && <RegistrationRibbon />}
    </Layout>
  )
}

export default ProductsMobile
